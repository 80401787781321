/* eslint-disable camelcase */
import './TEMPORARY_Header.scss'

import React, { useEffect, useRef, useState } from 'react'

import EsiHeader from './header'
import TrackedLink from '@/components/shared/tracked-link'
import { userSession } from '../../../helpers/userSession'

const TEMPORARY_Header = () => {
  let header = useRef()
  const [isLoggedIn, setIsLoggedIn] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const result = await userSession()
      setIsLoggedIn(result)
    }

    fetchData()

    header.current = new EsiHeader({
      rootSelector: '.esi-header',
      selectors: {
        header: 'header',
        nav: 'nav',
        navItems: 'nav-item',
        navLinks: 'nav-link',
        navPanels: 'nav-panel',
        toggle: 'toggle',
      },
    })

    header.current.getElements()
    header.current.addEvents()
  }, [])

  return (
    <>
      <header
        className="esi-header"
        data-esi-js-selector="header"
        data-nav-open=""
        id="rp-app-landmark-header"
      >
        <div className="esi-header__container">
          <div className="esi-header__home-link">
            <div className="esi-header__skipper">
              <ul className="esi-header-skipper">
                <li className="esi-header-skipper__item">
                  <TrackedLink
                    className="esi-header-skipper__link"
                    data-event-action="click"
                    data-event-category="Header: Skip links"
                    data-event-label="Skip to main content"
                    to="#rp-app-landmark-main"
                  >
                    Skip to main content
                  </TrackedLink>
                </li>
                <li className="esi-header-skipper__item">
                  <TrackedLink
                    className="esi-header-skipper__link"
                    data-event-action="click"
                    data-event-category="Header: Skip links"
                    data-event-label="Skip to footer"
                    to="#rp-app-landmark-footer"
                  >
                    Skip to footer
                  </TrackedLink>
                </li>
                <li className="esi-header-skipper__item">
                  <TrackedLink
                    className="esi-header-skipper__link"
                    data-event-action="click"
                    data-event-category="Header: Skip links"
                    data-event-label="Accessibility statement and help"
                    to="https://www.raspberrypi.org/accessibility/"
                  >
                    Accessibility statement and help
                  </TrackedLink>
                </li>
              </ul>
            </div>

            <TrackedLink
              className="esi-header-home-link"
              aria-label="Raspberry Pi Home page"
              data-event-action="click"
              data-event-category="Header"
              data-event-label="Home link"
              to="https://www.raspberrypi.org/"
            >
              <svg
                width="105"
                height="42"
                viewBox="0 0 165 64"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                style={{
                  clipRule: 'evenodd',
                  fillRule: 'evenodd',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 2,
                }}
              >
                <g transform="matrix(0.183333,0,0,0.183333,0,0)">
                  <g>
                    <g>
                      <path
                        d="M290.9,87.2L277.5,87.2L277.5,115.5C277.5,116.4 276.8,117.1 275.9,117.1L262.2,117.1C261.3,117.1 260.6,116.4 260.6,115.5L260.6,37.1C260.6,36.2 261.3,35.5 262.2,35.5L291,35.5C300.6,35.5 308.1,37.6 313.3,41.9C318.5,46.2 321.1,52.3 321.1,60.1C321.1,65.7 319.9,70.3 317.5,74C315.1,77.7 311.4,80.7 306.6,82.9L323.4,114.7C324,115.7 323.2,117 322,117L307.3,117C306.6,117 305.9,116.6 305.6,116L290.9,87.2ZM277.5,73.6L291,73.6C295.2,73.6 298.5,72.5 300.8,70.4C303.1,68.3 304.3,65.3 304.3,61.5C304.3,57.7 303.2,54.6 301,52.4C298.8,50.2 295.5,49.1 291,49.1L277.5,49.1L277.5,73.6Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M366.1,116.1C365.6,114.8 365.2,113.3 364.9,111.6C361,116 355.9,118.1 349.6,118.1C343.7,118.1 338.7,116.4 334.9,113C331,109.6 329.1,105.2 329.1,100C329.1,93.6 331.5,88.7 336.2,85.2C341,81.8 347.8,80 356.8,80L364.2,80L364.2,76.5C364.2,73.7 363.5,71.5 362,69.8C360.6,68.1 358.3,67.3 355.2,67.3C352.5,67.3 350.3,68 348.8,69.3C347.6,70.4 346.8,71.7 346.6,73.4C346.5,74.1 345.8,74.7 345.1,74.7L332,74.7C331,74.7 330.3,73.9 330.4,72.9C330.7,70.1 331.8,67.4 333.6,64.9C335.8,61.9 338.8,59.6 342.8,57.9C346.8,56.2 351.2,55.4 356.1,55.4C363.6,55.4 369.5,57.3 373.9,61C378.3,64.8 380.5,70 380.5,76.8L380.5,103C380.5,107.6 381,111.6 382.4,114.7C382.9,115.8 382.2,117 381,117L367.6,117C366.9,117 366.3,116.7 366.1,116.1ZM353.1,105.8C355.5,105.8 357.7,105.3 359.7,104.2C361.7,103.1 363.2,101.7 364.2,99.9L364.2,89.5L358.2,89.5C350.1,89.5 345.8,92.3 345.3,97.9L345.2,98.9C345.2,100.9 345.9,102.6 347.3,103.9C348.7,105.1 350.6,105.8 353.1,105.8Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M424.4,100.3C424.4,98.3 423.4,96.8 421.5,95.6C419.5,94.5 416.4,93.4 412.1,92.5C397.7,89.5 390.5,83.4 390.5,74.1C390.5,68.7 392.7,64.2 397.2,60.6C401.7,57 407.5,55.2 414.7,55.2C422.4,55.2 428.5,57 433.1,60.6C437.3,63.9 439.5,68 439.9,73.1C440,74 439.2,74.7 438.3,74.7L425.2,74.7C424.4,74.7 423.8,74.2 423.7,73.4C423.5,71.7 422.7,70.2 421.5,69C420,67.5 417.7,66.7 414.5,66.7C411.8,66.7 409.7,67.3 408.2,68.5C406.7,69.7 406,71.3 406,73.2C406,75 406.8,76.4 408.5,77.5C410.2,78.6 413.1,79.6 417.1,80.4C421.1,81.2 424.5,82.1 427.3,83.1C435.8,86.2 440.1,91.7 440.1,99.4C440.1,104.9 437.7,109.4 433,112.8C428.3,116.2 422.1,117.9 414.6,117.9C409.5,117.9 405,117 401.1,115.2C397.2,113.4 394.1,110.9 391.8,107.8C389.9,105.2 388.8,102.4 388.5,99.4C388.4,98.5 389.1,97.6 390.1,97.6L402.4,97.6C403.1,97.6 403.8,98.1 403.9,98.8C404.3,101.1 405.3,102.9 407,104.2C409,105.7 411.6,106.5 414.9,106.5C418,106.5 420.3,105.9 421.9,104.7C423.6,103.7 424.4,102.2 424.4,100.3Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M504.4,87.3C504.4,96.6 502.3,104.1 498,109.7C493.8,115.3 488,118.1 480.8,118.1C474.7,118.1 469.7,116 466,111.7L466,138.7C466,139.6 465.3,140.3 464.4,140.3L451.4,140.3C450.5,140.3 449.8,139.6 449.8,138.7L449.8,58C449.8,57.1 450.5,56.4 451.4,56.4L463.4,56.4C464.2,56.4 464.9,57 465,57.8L465.4,62.3C469.3,57.6 474.4,55.2 480.7,55.2C488.2,55.2 494,58 498.1,63.5C502.2,69 504.3,76.6 504.3,86.3L504.3,87.3L504.4,87.3ZM488.2,86.1C488.2,80.5 487.2,76.1 485.2,73.1C483.2,70 480.3,68.5 476.5,68.5C471.4,68.5 467.9,70.4 466,74.3L466,99.1C468,103.1 471.5,105.1 476.6,105.1C484.3,105.1 488.2,98.8 488.2,86.1Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M568.4,87.3C568.4,97 566.3,104.6 562.2,110C558.1,115.4 552.3,118.1 544.8,118.1C538.2,118.1 533,115.6 529.1,110.5L528.5,115.5C528.4,116.3 527.7,116.9 526.9,116.9L515.3,116.9C514.4,116.9 513.7,116.2 513.7,115.3L513.7,32.6C513.7,31.7 514.4,31 515.3,31L528.3,31C529.2,31 529.9,31.7 529.9,32.6L529.9,61.9C533.6,57.5 538.5,55.4 544.6,55.4C552,55.4 557.8,58.1 562,63.5C566.2,68.9 568.3,76.6 568.3,86.4L568.3,87.3L568.4,87.3ZM552.2,86.1C552.2,80 551.2,75.5 549.3,72.7C547.4,69.9 544.5,68.5 540.6,68.5C535.5,68.5 531.9,70.6 530,74.8L530,98.7C532,103 535.6,105.1 540.7,105.1C545.9,105.1 549.4,102.5 551,97.4C551.8,94.9 552.2,91.1 552.2,86.1Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M605.4,118.1C596.5,118.1 589.3,115.4 583.7,109.9C578.1,104.5 575.3,97.2 575.3,88.1L575.3,86.5C575.3,80.4 576.5,75 578.8,70.2C581.2,65.4 584.5,61.7 588.8,59.1C593.1,56.5 598,55.2 603.5,55.2C611.8,55.2 618.3,57.8 623.1,63C627.9,68.2 630.2,75.6 630.2,85.2L630.2,90.2C630.2,91.1 629.5,91.8 628.6,91.8L591.6,91.8C592.1,95.8 593.7,98.9 596.3,101.3C598.9,103.7 602.3,104.9 606.3,104.9C611.9,104.9 616.4,103.1 619.8,99.4C620.4,98.7 621.5,98.8 622.1,99.5L628,106.1C628.5,106.7 628.6,107.5 628.1,108.1C625.8,111 622.7,113.4 619,115.1C615.1,117.2 610.5,118.1 605.4,118.1ZM603.6,68.4C600.4,68.4 597.8,69.5 595.8,71.7C593.8,73.9 592.5,77 592,81L614.5,81L614.5,79.7C614.4,76.1 613.5,73.3 611.6,71.4C609.7,69.4 607,68.4 603.6,68.4Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M673.5,69.9C673.5,70.8 672.7,71.5 671.8,71.4C670.3,71.2 668.9,71.2 667.7,71.2C661.6,71.2 657.6,73.3 655.7,77.4L655.7,115.5C655.7,116.4 655,117.1 654.1,117.1L641.1,117.1C640.2,117.1 639.5,116.4 639.5,115.5L639.5,58C639.5,57.1 640.2,56.4 641.1,56.4L653.3,56.4C654.1,56.4 654.8,57 654.9,57.9L655.3,63.6C658.5,58 663,55.3 668.8,55.3C670.1,55.3 671.4,55.4 672.7,55.7C673.4,55.9 673.9,56.5 673.9,57.2L673.5,69.9Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M714.4,69.9C714.4,70.8 713.6,71.5 712.7,71.4C711.2,71.2 709.8,71.2 708.6,71.2C702.5,71.2 698.5,73.3 696.6,77.4L696.6,115.5C696.6,116.4 695.9,117.1 695,117.1L682,117.1C681.1,117.1 680.4,116.4 680.4,115.5L680.4,58C680.4,57.1 681.1,56.4 682,56.4L694.2,56.4C695,56.4 695.7,57 695.8,57.9L696.2,63.6C699.4,58 703.9,55.3 709.7,55.3C711,55.3 712.3,55.4 713.6,55.7C714.3,55.9 714.8,56.5 714.8,57.2L714.4,69.9Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M747,94.1L757.9,57.6C758.1,56.9 758.7,56.5 759.4,56.5L773.4,56.5C774.5,56.5 775.2,57.6 774.9,58.6L751.3,126.5L750,129.7C746.4,137.6 740.4,141.6 732.1,141.6C730.1,141.6 728.1,141.4 726.1,140.9C725.4,140.7 724.9,140.1 724.9,139.4L724.9,130C724.9,129.1 725.6,128.4 726.5,128.4L728.1,128.5C731.2,128.5 732.7,128 734.2,127C735.7,126.1 736.9,124.5 737.8,122.4L739.7,117.4L719.2,58.8C718.8,57.8 719.6,56.7 720.7,56.7L734.7,56.7C735.4,56.7 736,57.2 736.2,57.8L747,94.1Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M828.3,88.3L828.3,115.4C828.3,116.3 827.6,117 826.7,117L813,117C812.1,117 811.4,116.3 811.4,115.4L811.4,37.1C811.4,36.2 812.1,35.5 813,35.5L843.2,35.5C849.3,35.5 854.7,36.6 859.3,38.9C863.9,41.2 867.5,44.3 870,48.4C872.5,52.5 873.8,57.2 873.8,62.5C873.8,70.5 871.1,76.8 865.6,81.4C860.1,86 852.6,88.3 842.9,88.3L828.3,88.3ZM828.3,74.7L843.3,74.7C847.7,74.7 851.1,73.7 853.5,71.6C855.8,69.5 857,66.5 857,62.6C857,58.6 855.8,55.4 853.5,52.9C851.2,50.4 847.9,49.2 843.8,49.1L828.4,49.1L828.4,74.7L828.3,74.7Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                      <path
                        d="M881.9,40.8C881.9,38.4 882.7,36.4 884.3,34.8C885.9,33.2 888.1,32.4 890.9,32.4C893.7,32.4 895.9,33.2 897.5,34.8C899.1,36.4 900,38.4 900,40.8C900,43.3 899.2,45.3 897.5,46.8C895.8,48.4 893.6,49.2 890.9,49.2C888.2,49.2 886,48.4 884.3,46.8C882.7,45.3 881.9,43.2 881.9,40.8ZM897.5,117L884.4,117C883.5,117 882.8,116.3 882.8,115.4L882.8,58C882.8,57.1 883.5,56.4 884.4,56.4L897.5,56.4C898.4,56.4 899.1,57.1 899.1,58L899.1,115.4C899,116.3 898.3,117 897.5,117Z"
                        style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M211.5,156.5C210.3,143.9 204.6,132.7 196.6,126.8C195.2,121.7 192.8,117.2 189.7,113.3C189.2,107.4 186.7,95.8 175,85.5C176.3,84.3 177.5,83 178.4,81.4C188.2,77 195,67.9 195.8,61.1C201,55.9 204,49.7 204.2,43.6C204.3,40.7 203.8,38 202.7,35.7C207,31 208.4,25.6 206.6,20.2C203.7,11.8 193.5,6.3 179.8,5.7C176.1,2.3 171,0.6 164.9,0.6C162.3,0.6 159.5,0.9 156.8,1.6C154.6,0.5 151.9,0 148.9,0C143.8,0 138.1,1.6 133.8,4.1C133.1,4 132.5,4 131.9,4C125.1,4 118.3,8.7 114.8,11.5C111.4,14.3 108.3,17.5 105.8,20.8C103.3,17.5 100.2,14.2 96.8,11.5C93.5,8.7 86.6,4 79.8,4C79.2,4 78.6,4 78,4.1C73.7,1.6 68,0 62.9,0C59.9,0 57.2,0.6 55,1.6C52.3,0.9 49.6,0.6 46.9,0.6C40.8,0.6 35.6,2.4 32,5.7C18.2,6.3 8,11.8 5.1,20.2C3.3,25.5 4.7,31 9,35.7C7.9,38 7.4,40.7 7.5,43.6C7.7,49.7 10.7,55.9 15.9,61.1C16.7,67.9 23.6,77.1 33.3,81.4C34.2,83 35.4,84.4 36.7,85.5C25,95.7 22.5,107.3 22,113.3C18.8,117.2 16.5,121.7 15.1,126.8C7.1,132.7 1.4,143.9 0.2,156.5C-1,169 2.5,180.7 9.5,188.1C10.5,193.7 13.7,201.1 17.1,205.6C17.8,215.1 21.8,224.5 28.6,232.1C35.4,239.8 44.3,245.1 53.9,247.1C59.7,252.5 65.8,256.3 74.1,259.6C77.7,263.3 88.3,272 105.9,272C123.5,272 134.1,263.2 137.7,259.6C146,256.3 152.1,252.5 157.9,247.1C167.5,245.1 176.4,239.8 183.2,232.1C189.9,224.5 194,215.1 194.7,205.6C198.1,201.1 201.3,193.8 202.3,188.1C209.3,180.7 212.7,169 211.5,156.5Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M131.5,18.3C132.2,18.1 132.9,18.7 132.9,19.4C132.6,22.6 134.5,22.2 135,21.7C140.1,15.4 145.5,13.9 150.3,14.3C151.1,14.4 151.6,15.4 151,16C149.1,18.8 151.1,19.4 151.9,18.9C159.7,14 167.2,14 170.1,16C170.7,16.4 170.7,17.2 170.2,17.7C167.3,20.2 168.9,21.4 169.9,21C177.9,18.2 188.9,20.7 192.7,23.9C193.2,24.3 193.2,25.1 192.7,25.5C187.9,29.8 185.5,33.2 184.4,40C184.2,41.9 187.3,41.5 188.7,41.2C189.4,41 190,41.5 190,42.2C190.1,46.8 185.8,51.8 179.3,56.1C178.4,56.7 178.6,58.1 180.5,58.1C181.4,58.2 181.8,59 181.4,59.7C179.1,64 176,68 165.3,70.7C164.3,71 164.2,72.3 165.1,72.8C166,73.2 166.1,74.1 165.4,74.6C154.8,83.7 127.9,80.1 124.5,64.8C124.4,64.5 124.5,64.2 124.7,63.9C131.1,55.4 145.9,38.7 169.3,30C170.2,29.6 169.9,28.2 168.9,28.4C146.2,31.8 130.2,44.4 120.5,57.6C120.2,58 119.6,58.2 119.1,57.9C102.1,49.2 116.2,22.9 131.5,18.3Z"
                      style={{ fill: 'rgb(71,172,77)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M30.2,59.9C29.8,59.2 30.2,58.4 31.1,58.3C33.1,58.2 33.2,56.9 32.3,56.3C25.9,52 21.5,47 21.6,42.4C21.6,41.7 22.3,41.2 22.9,41.4C24.2,41.7 27.4,42.1 27.2,40.2C26.1,33.4 23.7,30 18.9,25.7C18.4,25.3 18.4,24.5 18.9,24.1C22.7,20.9 33.7,18.4 41.7,21.2C42.7,21.6 44.3,20.3 41.4,17.9C40.9,17.4 40.9,16.6 41.5,16.2C44.5,14 52,14 59.8,18.9C60.6,19.4 62.6,18.9 60.7,16C60.2,15.3 60.6,14.3 61.4,14.3C66.2,13.9 71.6,15.3 76.7,21.7C77.2,22.3 79.1,22.7 78.8,19.4C78.7,18.6 79.4,18 80.2,18.3C95.5,23 109.6,49.2 92.4,58.1C91.9,58.3 91.3,58.2 91,57.8C81.3,44.6 65.3,32 42.6,28.6C41.6,28.5 41.2,29.9 42.2,30.2C65.6,39 80.4,55.6 86.8,64.1C87,64.4 87.1,64.7 87,65C83.6,80.3 56.7,83.9 46.1,74.8C45.5,74.3 45.5,73.4 46.4,73C47.3,72.6 47.3,71.2 46.2,70.9C35.6,68.2 32.5,64.1 30.2,59.9Z"
                      style={{ fill: 'rgb(71,172,77)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M173.8,106.8C178.2,118.6 174.8,124.2 164.3,118.2C156,113.7 148.2,107.1 142.3,99.7C134.4,90.3 139.5,86.1 152,88.4C161.2,90.7 170,98 173.8,106.8Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M126.8,93.5C141.8,106 124.6,114.8 105.9,114.8C87.2,114.8 70,106 85,93.5C94,86.5 102.6,86.2 105.9,86.2C109.2,86.2 117.8,86.5 126.8,93.5Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M38,106.8C41.8,98.1 50.6,90.7 59.9,88.5C72.3,86.2 77.5,90.4 69.6,99.8C63.7,107.2 55.9,113.7 47.6,118.3C37,124.2 33.6,118.5 38,106.8Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M16.3,172.1C12.8,163 13.7,152 18.8,143.5C24.3,134.1 29.9,134.5 32.1,142.4C35,152.9 33.9,165.5 29.2,175.4C25.1,183.6 20,180.9 16.3,172.1Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M56.2,233.1C42.9,230.3 31.6,216.9 31.1,203.3C33.2,155 102.9,241.4 56.2,233.1Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M57.6,172.9C45.9,166.1 42.8,149.6 50.7,136C58.6,122.4 74.4,116.8 86.1,123.5C97.8,130.3 100.9,146.8 93,160.4C85.1,174 69.3,179.6 57.6,172.9Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M125.4,251.6C119.9,255.6 113.2,257.9 105.9,257.9C98.6,257.9 91.9,255.6 86.4,251.6C74.8,243.6 86.5,234.4 105.9,234.4C125.3,234.4 137,243.6 125.4,251.6Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <ellipse
                      cx="105.9"
                      cy="199"
                      rx="27.1"
                      ry="23.3"
                      style={{ fill: 'rgb(204,36,85)' }}
                    />
                    <path
                      d="M118.7,160.4C110.8,146.8 113.9,130.2 125.6,123.5C137.3,116.7 153.2,122.3 161,136C168.9,149.6 165.8,166.2 154.1,172.9C142.4,179.6 126.5,174 118.7,160.4Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M155.5,233.1C108.8,241.3 178.5,154.9 180.6,203.3C180.1,216.9 168.8,230.3 155.5,233.1Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M195.5,172.1C191.8,180.9 186.7,183.6 182.6,175.5C178,165.6 176.9,153 179.7,142.5C181.9,134.5 187.5,134.1 193,143.6C198.1,152 198.9,163 195.5,172.1Z"
                      style={{ fill: 'rgb(204,36,85)', fillRule: 'nonzero' }}
                    />
                  </g>
                  <g>
                    <path
                      d="M306.1,203.4L271.9,203.4L271.9,239.4L261.2,239.4L261.2,157.9L311.7,157.9L311.7,166.7L271.9,166.7L271.9,194.6L306.1,194.6L306.1,203.4Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M317.1,208.6C317.1,202.7 318.3,197.3 320.6,192.6C322.9,187.9 326.2,184.2 330.3,181.6C334.5,179 339.2,177.7 344.5,177.7C352.7,177.7 359.4,180.6 364.5,186.3C369.6,192 372.1,199.6 372.1,209.1L372.1,209.8C372.1,215.7 371,221 368.7,225.7C366.4,230.4 363.2,234 359,236.6C354.8,239.2 350,240.5 344.5,240.5C336.3,240.5 329.6,237.6 324.5,231.9C319.4,226.2 316.9,218.6 316.9,209.2L316.9,208.6L317.1,208.6ZM327.6,209.8C327.6,216.5 329.2,221.9 332.3,226C335.4,230.1 339.6,232.1 344.8,232.1C350.1,232.1 354.2,230 357.3,225.9C360.4,221.8 361.9,216 361.9,208.6C361.9,202 360.3,196.6 357.2,192.4C354,188.3 349.9,186.2 344.6,186.2C339.5,186.2 335.4,188.2 332.2,192.3C329.1,196.4 327.6,202.3 327.6,209.8Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M421.1,233.5C417.1,238.2 411.2,240.6 403.3,240.6C396.8,240.6 391.9,238.7 388.5,235C385.1,231.3 383.4,225.7 383.4,218.3L383.4,178.9L393.8,178.9L393.8,218C393.8,227.2 397.5,231.8 405,231.8C412.9,231.8 418.2,228.9 420.8,223L420.8,178.9L431.2,178.9L431.2,239.5L421.3,239.5L421.1,233.5Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M455,178.9L455.3,186.5C459.9,180.7 466,177.8 473.4,177.8C486.2,177.8 492.7,185 492.8,199.5L492.8,239.5L482.4,239.5L482.4,199.4C482.4,195 481.4,191.8 479.4,189.7C477.4,187.6 474.4,186.6 470.2,186.6C466.8,186.6 463.9,187.5 461.4,189.3C458.9,191.1 456.9,193.4 455.5,196.4L455.5,239.6L445.1,239.6L445.1,179L455,179L455,178.9Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M504.1,208.7C504.1,199.4 506.3,191.9 510.7,186.3C515.1,180.6 520.9,177.8 528,177.8C535.1,177.8 540.7,180.2 544.9,185.1L544.9,153.5L555.3,153.5L555.3,239.5L545.8,239.5L545.3,233C541.2,238.1 535.4,240.6 528,240.6C521,240.6 515.3,237.7 510.8,232C506.4,226.3 504.2,218.8 504.2,209.5L504.2,208.7L504.1,208.7ZM514.4,209.8C514.4,216.7 515.8,222 518.7,225.9C521.5,229.8 525.5,231.7 530.5,231.7C537.1,231.7 541.9,228.8 544.9,222.9L544.9,195.1C541.8,189.4 537,186.5 530.6,186.5C525.5,186.5 521.6,188.5 518.7,192.4C515.9,196.3 514.4,202.1 514.4,209.8Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M607.2,239.4C606.6,238.2 606.1,236.1 605.7,233C600.9,238 595.1,240.5 588.5,240.5C582.5,240.5 577.6,238.8 573.8,235.4C570,232 568.1,227.7 568.1,222.6C568.1,216.3 570.5,211.4 575.3,207.9C580.1,204.4 586.8,202.7 595.5,202.7L605.6,202.7L605.6,198C605.6,194.4 604.5,191.5 602.4,189.3C600.2,187.2 597,186.1 592.8,186.1C589.1,186.1 586,187 583.5,188.9C581,190.8 579.7,193 579.7,195.7L569.3,195.7C569.3,192.7 570.4,189.8 572.5,186.9C574.6,184 577.6,181.9 581.2,180.2C584.9,178.6 588.9,177.7 593.3,177.7C600.3,177.7 605.7,179.4 609.7,182.9C613.7,186.4 615.7,191.2 615.9,197.3L615.9,225.2C615.9,230.8 616.6,235.2 618,238.5L618,239.4L607.2,239.4ZM590,231.6C593.2,231.6 596.3,230.8 599.2,229.1C602.1,227.4 604.2,225.2 605.5,222.5L605.5,210.1L597.4,210.1C584.7,210.1 578.4,213.8 578.4,221.2C578.4,224.4 579.5,227 581.6,228.8C583.7,230.6 586.6,231.6 590,231.6Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M645.1,164.1L645.1,178.9L656.4,178.9L656.4,186.9L645.1,186.9L645.1,224.5C645.1,226.9 645.6,228.7 646.6,230C647.6,231.2 649.3,231.8 651.7,231.8C652.9,231.8 654.5,231.6 656.6,231.1L656.6,239.4C653.9,240.1 651.2,240.5 648.6,240.5C644,240.5 640.5,239.1 638.1,236.3C635.7,233.5 634.6,229.5 634.6,224.4L634.6,186.8L623.6,186.8L623.6,178.8L634.6,178.8L634.6,164.1L645.1,164.1Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M667.4,162.8C667.4,161.1 667.9,159.7 668.9,158.5C669.9,157.3 671.4,156.8 673.5,156.8C675.5,156.8 677,157.4 678.1,158.5C679.1,159.7 679.7,161.1 679.7,162.8C679.7,164.5 679.2,165.9 678.1,167C677.1,168.1 675.5,168.7 673.5,168.7C671.5,168.7 670,168.1 668.9,167C667.9,165.9 667.4,164.5 667.4,162.8ZM678.6,239.4L668.2,239.4L668.2,178.8L678.6,178.8L678.6,239.4Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M691.2,208.6C691.2,202.7 692.4,197.3 694.7,192.6C697,187.9 700.3,184.2 704.4,181.6C708.6,179 713.3,177.7 718.6,177.7C726.8,177.7 733.5,180.6 738.6,186.3C743.7,192 746.2,199.6 746.2,209.1L746.2,209.8C746.2,215.7 745.1,221 742.8,225.7C740.5,230.4 737.3,234 733.1,236.6C728.9,239.2 724.1,240.5 718.6,240.5C710.4,240.5 703.7,237.6 698.6,231.9C693.5,226.2 691,218.6 691,209.2L691,208.6L691.2,208.6ZM701.7,209.8C701.7,216.5 703.3,221.9 706.4,226C709.5,230.1 713.7,232.1 718.9,232.1C724.2,232.1 728.3,230 731.4,225.9C734.5,221.8 736,216 736,208.6C736,202 734.4,196.6 731.3,192.4C728.2,188.2 724,186.2 718.7,186.2C713.6,186.2 709.5,188.2 706.3,192.3C703.2,196.4 701.7,202.3 701.7,209.8Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M767.3,178.9L767.6,186.5C772.2,180.7 778.3,177.8 785.7,177.8C798.5,177.8 805,185 805.1,199.5L805.1,239.5L794.7,239.5L794.7,199.4C794.7,195 793.7,191.8 791.7,189.7C789.7,187.6 786.7,186.6 782.5,186.6C779.1,186.6 776.2,187.5 773.7,189.3C771.2,191.1 769.2,193.4 767.8,196.4L767.8,239.6L757.4,239.6L757.4,179L767.3,179L767.3,178.9Z"
                      style={{ fill: 'rgb(29,29,27)', fillRule: 'nonzero' }}
                    />
                  </g>
                </g>
              </svg>
            </TrackedLink>
          </div>

          <div className="esi-header__nav">
            <nav
              aria-label="Navigation"
              className="esi-header-nav"
              data-esi-js-selector="nav"
              data-active-panel=""
            >
              <ul className="esi-header-nav__list">
                <li
                  className="esi-header-nav__item"
                  data-esi-js-selector="nav-item"
                  data-section="learn"
                >
                  <TrackedLink
                    className="esi-header-nav-link esi-header-nav-link--learn"
                    data-esi-js-selector="nav-link"
                    data-event-action="click"
                    data-event-category="Header: Navigation"
                    data-event-label="Learn"
                    data-section="learn"
                    to="/learn/"
                  >
                    Learn
                  </TrackedLink>

                  <div
                    aria-expanded="false"
                    aria-hidden="true"
                    className="esi-header-nav__panel"
                    data-section="learn"
                    data-esi-js-selector="nav-panel"
                  >
                    <div className="esi-header-nav-panel">
                      <div className="esi-header-nav-panel__container esi-header-nav-panel__container--learn">
                        <div className="esi-header-nav-panel__inner">
                          <div className="esi-header-nav-panel__primary-cta">
                            <p className="esi-header-nav-panel__summary">
                              Free resources for young people to learn to code
                              and become digital makers
                            </p>

                            <div className="esi-header-nav-panel__buttons">
                              <TrackedLink
                                className="sauce-button esi-header-nav-panel__button"
                                data-event-action="click"
                                data-event-category="Header: Learn panel - button"
                                data-event-label="Learn to code"
                                to="/learn/"
                              >
                                Learn to code
                              </TrackedLink>
                            </div>
                          </div>

                          <ul className="esi-header-nav-panel__list">
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Learn panel - link"
                                data-event-label="Learn to code online"
                                to="https://codeclubworld.org/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Learn to code online
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Learn panel - link"
                                data-event-label="Learn at a Code Club"
                                to="https://codeclub.org/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Learn at a Code Club
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Learn panel - link"
                                data-event-label="Explore our projects"
                                to="https://projects.raspberrypi.org/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Explore our projects
                                </span>
                              </TrackedLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="esi-header-nav__item"
                  data-esi-js-selector="nav-item"
                  data-section="teach"
                >
                  <TrackedLink
                    className="esi-header-nav-link esi-header-nav-link--teach"
                    data-esi-js-selector="nav-link"
                    data-event-action="click"
                    data-event-category="Header: Navigation"
                    data-event-label="Teach"
                    data-section="teach"
                    to="/teach/"
                  >
                    Teach
                  </TrackedLink>

                  <div
                    aria-expanded="false"
                    aria-hidden="true"
                    className="esi-header-nav__panel"
                    data-section="teach"
                    data-esi-js-selector="nav-panel"
                  >
                    <div className="esi-header-nav-panel">
                      <div className="esi-header-nav-panel__container esi-header-nav-panel__container--teach">
                        <div className="esi-header-nav-panel__inner">
                          <div className="esi-header-nav-panel__primary-cta">
                            <p className="esi-header-nav-panel__summary">
                              Free training, resources, and guidance to help you
                              teach computing with confidence
                            </p>

                            <div className="esi-header-nav-panel__buttons">
                              <TrackedLink
                                className="sauce-button esi-header-nav-panel__button"
                                data-event-action="click"
                                data-event-category="Header: Teach panel - button"
                                data-event-label="Support for teachers"
                                to="/teach/"
                              >
                                Support for teachers
                              </TrackedLink>
                            </div>
                          </div>

                          <ul className="esi-header-nav-panel__list">
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Teach panel - link"
                                data-event-label="The Computing Curriculum"
                                to="https://www.raspberrypi.org/curriculum/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  The Computing Curriculum
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Teach panel - link"
                                data-event-label="Ada Computer Science"
                                to="https://adacomputerscience.org/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Ada Computer Science
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Teach panel - link"
                                data-event-label="Online training courses"
                                to="https://www.raspberrypi.org/training/online/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Online training courses
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Teach panel - link"
                                data-event-label="Hello World Magazine"
                                to="https://www.raspberrypi.org/hello-world"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Hello World magazine
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Teach panel - link"
                                data-event-label="Start a Code Club"
                                to="https://codeclub.org/en/start-a-code-club/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Start a Code Club
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Teach panel - link"
                                data-event-label="AI education"
                                to="https://www.raspberrypi.org/teach/ai-education"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  AI education
                                </span>
                              </TrackedLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="esi-header-nav__item"
                  data-esi-js-selector="nav-item"
                  data-section="research"
                >
                  <TrackedLink
                    className="esi-header-nav-link esi-header-nav-link--research"
                    data-esi-js-selector="nav-link"
                    data-event-action="click"
                    data-event-category="Header: Navigation"
                    data-event-label="Research"
                    data-section="research"
                    to="https://www.raspberrypi.org/research/"
                  >
                    Research
                  </TrackedLink>

                  <div
                    aria-expanded="false"
                    aria-hidden="true"
                    className="esi-header-nav__panel"
                    data-section="research"
                    data-esi-js-selector="nav-panel"
                  >
                    <div className="esi-header-nav-panel">
                      <div className="esi-header-nav-panel__container esi-header-nav-panel__container--research">
                        <div className="esi-header-nav-panel__inner">
                          <div className="esi-header-nav-panel__primary-cta">
                            <p className="esi-header-nav-panel__summary">
                              Deepen your understanding of how young people
                              learn about computing and digital making
                            </p>

                            <div className="esi-header-nav-panel__buttons">
                              <TrackedLink
                                className="sauce-button esi-header-nav-panel__button"
                                data-event-action="click"
                                data-event-category="Header: Research panel - button"
                                data-event-label="Explore our research"
                                to="https://www.raspberrypi.org/research/"
                              >
                                Explore our research
                              </TrackedLink>
                            </div>
                          </div>

                          <ul className="esi-header-nav-panel__list">
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Research panel - link"
                                data-event-label="Online events and seminars"
                                to="https://www.raspberrypi.org/research/seminars"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Online events and seminars
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Research panel - link"
                                data-event-label="Research projects"
                                to="https://www.raspberrypi.org/research/projects"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Research projects
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: Research panel - link"
                                data-event-label="Research publications"
                                to="https://www.raspberrypi.org/research/publications"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Research publications
                                </span>
                              </TrackedLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="esi-header-nav__item"
                  data-esi-js-selector="nav-item"
                  data-section="computers"
                >
                  <TrackedLink
                    className="esi-header-nav-link esi-header-nav-link--computers"
                    data-esi-js-selector="nav-link"
                    data-event-action="click"
                    data-event-category="Header: Navigation"
                    data-event-label="Computers"
                    data-section="computers"
                    to="https://www.raspberrypi.com"
                  >
                    Computers
                  </TrackedLink>

                  <div
                    aria-expanded="false"
                    aria-hidden="true"
                    className="esi-header-nav__panel"
                    data-section="computers"
                    data-esi-js-selector="nav-panel"
                  >
                    <div className="esi-header-nav-panel">
                      <div className="esi-header-nav-panel__container esi-header-nav-panel__container--computers">
                        <div className="esi-header-nav-panel__inner">
                          <div className="esi-header-nav-panel__primary-cta">
                            <p className="esi-header-nav-panel__summary">
                              Raspberry Pi computers and accessories, software
                              and documentation
                            </p>

                            <div className="esi-header-nav-panel__buttons">
                              <TrackedLink
                                className="sauce-button esi-header-nav-panel__button"
                                data-event-action="click"
                                data-event-category="Header: Computers panel - button"
                                data-event-label="Visit raspberrypi.com"
                                to="https://www.raspberrypi.com/"
                              >
                                Visit raspberrypi.com
                              </TrackedLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="esi-header-nav__item"
                  data-esi-js-selector="nav-item"
                  data-section="about"
                >
                  <TrackedLink
                    className="esi-header-nav-link esi-header-nav-link--about"
                    data-esi-js-selector="nav-link"
                    data-event-action="click"
                    data-event-category="Header: Navigation"
                    data-event-label="About us"
                    data-section="about"
                    to="https://www.raspberrypi.org/about/"
                  >
                    About us
                  </TrackedLink>

                  <div
                    aria-expanded="false"
                    aria-hidden="true"
                    className="esi-header-nav__panel"
                    data-section="about"
                    data-esi-js-selector="nav-panel"
                  >
                    <div className="esi-header-nav-panel">
                      <div className="esi-header-nav-panel__container esi-header-nav-panel__container--about">
                        <div className="esi-header-nav-panel__inner">
                          <div className="esi-header-nav-panel__primary-cta">
                            <p className="esi-header-nav-panel__summary">
                              We enable young people to realise their full
                              potential through the power of computing and
                              digital technologies
                            </p>

                            <div className="esi-header-nav-panel__buttons">
                              <TrackedLink
                                className="sauce-button esi-header-nav-panel__button"
                                data-event-action="click"
                                data-event-category="Header: About us panel - button"
                                data-event-label="About us"
                                to="https://www.raspberrypi.org/about/"
                              >
                                About us
                              </TrackedLink>
                            </div>
                          </div>

                          <ul className="esi-header-nav-panel__list">
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: About us panel - link"
                                data-event-label="Support us"
                                to="/support-us/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Support us
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: About us panel - link"
                                data-event-label="Blog"
                                to="https://www.raspberrypi.org/blog/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Blog
                                </span>
                              </TrackedLink>
                            </li>
                            <li className="esi-header-nav-panel__item">
                              <TrackedLink
                                className="sc-rp-link esi-header-nav-panel__link"
                                data-event-action="click"
                                data-event-category="Header: About us panel - link"
                                data-event-label="Community stories"
                                to="/community-stories/"
                              >
                                <svg
                                  className="esi-header-nav-panel__icon"
                                  fill="none"
                                  height="18"
                                  viewBox="0 0 11 18"
                                  width="11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 2.5L8.5 9L2 15.5"
                                    stroke="#004881"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>

                                <span className="esi-header-nav-panel__label">
                                  Community stories
                                </span>
                              </TrackedLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="esi-header-nav__item"
                  data-esi-js-selector="nav-item"
                  data-section="donate"
                >
                  <TrackedLink
                    className="sauce-button sauce-button--nav"
                    data-esi-js-selector="nav-link"
                    data-event-action="click"
                    data-event-category="Header: Navigation"
                    data-event-label="Donate"
                    data-section="donate"
                    to="/donate/"
                  >
                    Donate
                  </TrackedLink>

                  <div
                    aria-expanded="false"
                    aria-hidden="true"
                    className="esi-header-nav__panel"
                    data-section="donate"
                    data-esi-js-selector="nav-panel"
                  ></div>
                </li>
                {isLoggedIn && (
                  <li
                    className="esi-header-nav__item"
                    data-esi-js-selector="nav-item"
                    data-section="logout"
                  >
                    <TrackedLink
                      className="sauce-button sauce-button--nav sauce-button--logout"
                      data-esi-js-selector="nav-link"
                      data-event-action="click"
                      data-event-category="Header: Navigation"
                      data-event-label="Logout"
                      data-section="logout"
                      to="https://www.raspberrypi.org/auth/logout"
                    >
                      Log out
                    </TrackedLink>

                    <div
                      aria-expanded="false"
                      aria-hidden="true"
                      className="esi-header-nav__panel"
                      data-section="logout"
                      data-esi-js-selector="nav-panel"
                    ></div>
                  </li>
                )}
              </ul>
            </nav>
          </div>

          <div className="esi-header__persistent-nav">
            <TrackedLink
              className="sauce-button sauce-button--nav"
              data-esi-js-selector="nav-link"
              data-event-action="click"
              data-event-category="Header: Navigation"
              data-event-label="Donate"
              data-section="donate"
              to="/donate/"
            >
              Donate
            </TrackedLink>
            {isLoggedIn && (
              <TrackedLink
                className="sauce-button sauce-button--nav sauce-button--logout"
                data-esi-js-selector="nav-link"
                data-event-action="click"
                data-event-category="Header: Navigation"
                data-event-label="Logout"
                data-section="logout"
                to="https://www.raspberrypi.org/auth/logout"
              >
                Log out
              </TrackedLink>
            )}
          </div>
          <div className="esi-header__toggle">
            <button
              aria-label="Open Navigation"
              aria-pressed="false"
              className="esi-header-toggle"
              data-esi-js-selector="toggle"
              type="button"
            >
              <span className="esi-header-toggle__line"></span>
              <span className="esi-header-toggle__line"></span>
              <span className="esi-header-toggle__line"></span>
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default TEMPORARY_Header
