import './Footer.scss'

import config from './footer.json'
import React, { useContext } from 'react'
import ApplicationContext from '@/contexts/ApplicationContext'
import TrackedLink from '@/components/shared/tracked-link'

const Footer = () => {
  const { landmarks } = useContext(ApplicationContext)
  const { legal, navigationLists, socialLinks } = config

  const footerLink = (link) => {
    if (!link.href) {
      return link.label
    }

    return (
      <TrackedLink
        className="c-footer__nav-link"
        data-event-action="click"
        data-event-category="Footer Nav Link"
        data-event-label={link.label}
        to={link.href}
      >
        {link.label}
      </TrackedLink>
    )
  }

  return (
    <footer className="c-footer" id={landmarks.footer}>
      <nav className="c-footer__nav">
        <div className="c-footer__nav-container">
          {navigationLists.map((links) => (
            <ul
              className="c-footer__nav-list"
              key={`Footer List: ${links[0].label}`}
            >
              {links.map((link) => (
                <li
                  className="c-footer__nav-item"
                  key={`Footer Link: ${link.label}`}
                >
                  {footerLink(link)}
                </li>
              ))}
            </ul>
          ))}
        </div>
      </nav>

      <div className="c-footer__additional">
        <ul className="c-footer__social-list">
          {Object.entries(socialLinks).map(([network, link]) => (
            <li className="c-footer__social-item" key={network}>
              <TrackedLink
                class={`c-footer__social-link c-footer__social-link--${network}`}
                data-event-action="click"
                data-event-category="Footer Social Link"
                data-event-label={network}
                to={link.href}
              >
                <span className="c-footer__social-cta">{link.label}</span>
              </TrackedLink>
            </li>
          ))}
        </ul>

        <p className="c-footer__legal">{legal}</p>
      </div>
    </footer>
  )
}

export default Footer
