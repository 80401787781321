import UrlHelper from '@/helpers/UrlHelper'

const DEFAULT_DESCRIPTION =
  'The Raspberry Pi is a tiny and affordable computer that you can use to learn programming through fun, practical projects. Join the global Raspberry Pi community.'
const DEFAULT_IMAGE = 'images/default-opengraph.png'
const DEFAULT_TITLE = 'Teach, Learn, and Make with Raspberry Pi'
const SITE_NAME = 'Raspberry Pi'
const TITLE_TEMPLATE = `%s – ${SITE_NAME}`

const BASE_META = [
  { name: 'author', content: 'The Raspberry Pi Foundation' },
  { name: 'charset', content: 'UTF-8' },
  { name: 'format-detection', content: 'telephone=no' },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:creator', content: SITE_NAME },
  { name: 'twitter:site', content: SITE_NAME },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, viewport-fit=cover',
  },
  { property: 'og:site_name', content: SITE_NAME },
  { property: 'og:type', content: 'website' },
]

// eslint-disable-next-line complexity
const imageSrc = (image = DEFAULT_IMAGE) => {
  let imageUrl = image

  if (image.url) {
    imageUrl = image.url
  }

  if (image.localFile) {
    imageUrl = image.localFile.childImageSharp.fluid.src
  }

  if (/^http/i.test(imageUrl)) {
    return imageUrl
  }

  return UrlHelper.url(imageUrl)
}

const pageTitle = (title) => [title || DEFAULT_TITLE, SITE_NAME].join(' – ')

const values = ({ description, image, title }) => {
  title = MetaHelper.pageTitle(title)
  image = MetaHelper.imageSrc(image)

  if (!description) {
    description = DEFAULT_DESCRIPTION
  }

  return [].concat(BASE_META, [
    { name: 'description', content: description },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:title', content: title },
  ])
}

const MetaHelper = {
  DEFAULT_DESCRIPTION,
  DEFAULT_IMAGE,
  DEFAULT_TITLE,
  SITE_NAME,
  TITLE_TEMPLATE,
  imageSrc,
  pageTitle,
  values,
}

export default MetaHelper
