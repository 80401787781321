import { Helmet } from 'react-helmet'
import MetaHelper from '@/helpers/MetaHelper'
import PropTypes from 'prop-types'
import React from 'react'

const PageMeta = ({ children, description, image, title }) => {
  const meta = MetaHelper.values({ description, image, title })

  return (
    <Helmet
      defaultTitle={MetaHelper.DEFAULT_TITLE}
      meta={meta}
      title={title}
      titleTemplate={MetaHelper.TITLE_TEMPLATE}
    >
      {children}
    </Helmet>
  )
}

PageMeta.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
}

export default PageMeta
