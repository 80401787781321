/* eslint-disable camelcase */
import window from '@/helpers/WindowHelper'

export const trackEvent = (event) => {
  if (typeof window.gtag === 'undefined') {
    return
  }

  window.gtag('event', 'click', eventData(event.currentTarget.dataset))
}

export const eventData = (data) => {
  return {
    ...(data.eventAction && { event_action: data.eventAction }),
    ...(data.eventCategory && { event_category: data.eventCategory }),
    ...(data.eventLabel && { event_label: data.eventLabel }),
    ...(data.eventValue && { value: data.eventValue }),
  }
}

const EventTrackingHelper = {
  trackEvent,
}

export default EventTrackingHelper
