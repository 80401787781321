export const siteUrl = process.env.GATSBY_SITE_URL

export const isExternalUrl = (to) => !UrlHelper.isJamstackPath(to)

export const isJamstackPath = (to) =>
  ['//', 'http', 'mailto', 'tel'].every((str) => !to.startsWith(str))

export const trimLeadingSlash = (string) => {
  if (string.startsWith('/')) {
    return string.slice(1)
  }

  return string
}

export const trimTrailingSlash = (string) => {
  if (string.endsWith('/')) {
    return string.slice(0, -1)
  }

  return string
}

export const url = (base, path) => {
  if (!path) {
    path = base
    base = UrlHelper.siteUrl
  }

  return [
    UrlHelper.trimTrailingSlash(base),
    UrlHelper.trimLeadingSlash(path),
  ].join('/')
}

const UrlHelper = {
  siteUrl,
  isExternalUrl,
  isJamstackPath,
  trimLeadingSlash,
  trimTrailingSlash,
  url,
}

export default UrlHelper
