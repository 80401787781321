let windowObject = {
  history: {
    replaceState: () => {},
  },
  localStorage: {
    getItem: () => {},
    setItem: () => {},
  },
  location: {
    search: '',
  },
  navigator: {
    userAgent: 'mock',
  },
  sessionStorage: {
    getItem: () => {},
    setItem: () => {},
  },
}

if (typeof window !== 'undefined') {
  windowObject = window
}

const { userAgent } = windowObject.navigator

export const isSyntheticBrowser = userAgent.includes('mock')
export const isBrowser = !isSyntheticBrowser

export default windowObject
