const BASE_URL = process.env.SESSION_BASE_URL || ''

const HTTP_STATUS = {
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
}

export const userSession = async () => {
  const url = `${BASE_URL}/session/logged_in`
  const requestOptions = {}

  if (process.env.NODE_ENV === 'development') {
    requestOptions.credentials = 'include'
  }

  try {
    const response = await fetch(url, requestOptions)

    if (response.status === HTTP_STATUS.NO_CONTENT) {
      return true
    }

    if (response.status === HTTP_STATUS.UNAUTHORIZED) {
      return false
    }
  } catch (error) {
    return { error: error.toString() }
  }
}
