import './Application.scss'

import { ApplicationContextProvider } from '@/contexts/ApplicationContext'
import Footer from '@/components/Application/Footer'
import Main from '@/components/Application/Main'
import PageMeta from '@/components/Application/PageMeta'
import PropTypes from 'prop-types'
import React from 'react'
import SiteHead from '@/components/Application/SiteHead'
// eslint-disable-next-line camelcase
import TEMPORARY_Header from '@/components/Application/TEMPORARY_Header'

const ApplicationLayout = ({ children, preferBits, useRoboto }) => {
  return (
    <ApplicationContextProvider preferBits={preferBits} useRoboto={useRoboto}>
      <SiteHead />
      <PageMeta />
      <TEMPORARY_Header />
      <Main>{children}</Main>
      <Footer />
    </ApplicationContextProvider>
  )
}

ApplicationLayout.defaultProps = {
  preferBits: false,
}

ApplicationLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  preferBits: PropTypes.bool,
  useRoboto: PropTypes.bool,
}

export default ApplicationLayout
