import './Main.scss'

import React, { useContext } from 'react'

import ApplicationContext from '@/contexts/ApplicationContext'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Main = ({ children }) => {
  const { styles, landmarks } = useContext(ApplicationContext)

  return (
    <main
      className={classnames('o-main', styles)}
      id={landmarks.main}
      tabIndex="-1"
    >
      {children}
    </main>
  )
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
}

export default Main
