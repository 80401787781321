import PropTypes from 'prop-types'
import React from 'react'

const ApplicationContext = React.createContext({
  styles: '',
})

const ApplicationContextProvider = ({ children, preferBits, useRoboto }) => {
  const landmarks = {
    footer: 'rp-app-landmark-footer',
    header: 'rp-app-header',
    main: 'rp-app-landmark-main',
  }

  let styles = 'sauce'

  if (useRoboto) {
    styles = 'sauce sauce--roboto'
  } else if (preferBits) {
    styles = 'bits'
  }

  const applicationContextValue = {
    landmarks,
    styles,
  }

  return (
    <ApplicationContext.Provider value={applicationContextValue}>
      {children}
    </ApplicationContext.Provider>
  )
}

ApplicationContextProvider.defaultProps = {
  preferBits: false,
}

ApplicationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  preferBits: PropTypes.bool,
  useRoboto: PropTypes.bool,
}

export default ApplicationContext
export { ApplicationContextProvider }
