class EsiHeader {
  constructor(options) {
    this.rootSelector = options.rootSelector
    this.selectors = options.selectors || {}

    this.activePanel = ''
    this.elements = {}
    this.navOpen = false
    this.debounceTimeout = 200
    this.root = this.getRootElement()
  }

  getElements() {
    Object.entries(this.selectors).map(([name, selector]) => {
      const elements = document.querySelectorAll(
        `[data-esi-js-selector="${selector}"]`
      )

      if (elements.length === 1) {
        this.elements[name] = elements[0]
        return
      }

      this.elements[name] = elements
    })
  }

  getRootElement() {
    return document.querySelector(this.rootSelector)
  }

  addEvents() {
    this.elements.nav.addEventListener('focusout', (event) => {
      const { currentTarget, relatedTarget } = event

      if (!currentTarget.contains(relatedTarget)) {
        this.navPanelClose()
      }
    })

    Array.from(this.elements.navItems).forEach((navItem) => {
      navItem.addEventListener(
        'mouseenter',
        this.debounce(this.navPanelOpen.bind(this))
      )

      navItem.addEventListener(
        'mouseleave',
        this.debounce(this.navPanelClose.bind(this))
      )
    })

    Array.from(this.elements.navLinks).forEach((navLink) => {
      navLink.addEventListener('focus', (event) => this.navPanelOpen(event))
    })

    this.elements.toggle.addEventListener('click', () => this.toggleHandler())
  }

  debounce(callback) {
    this.timeout = null
    return (...args) => {
      const next = () => callback(...args)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(next, this.debounceTimeout)
    }
  }

  navPanelClose() {
    this.activePanel = ''
    this.updateNavAttributes()
  }

  navPanelOpen(event) {
    this.activePanel = event.target.dataset.section
    this.updateNavAttributes()
  }

  toggleHandler() {
    this.navOpen = !this.navOpen

    this.elements.header.dataset.navOpen = this.navOpen
    this.elements.toggle.setAttribute('aria-pressed', this.navOpen)
  }

  updateNavAttributes() {
    this.elements.nav.dataset.activePanel = this.activePanel

    Array.from(this.elements.navPanels).forEach((panel) => {
      let expanded = false
      let hidden = true

      if (panel.dataset.section === this.activePanel) {
        expanded = true
        hidden = false
      }

      panel.setAttribute('aria-expanded', expanded)
      panel.setAttribute('aria-hidden', hidden)
    })
  }
}

export default EsiHeader
