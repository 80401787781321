/* eslint-disable no-console */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import UrlHelper from '@/helpers/UrlHelper'
import { trackEvent } from '@/helpers/event-tracking'

export const linkComponent = (to) => {
  if (UrlHelper.isExternalUrl(to)) {
    return 'a'
  }

  return Link
}

export const linkDestination = (to) => {
  if (UrlHelper.isExternalUrl(to)) {
    return { href: to }
  }

  return { to }
}

export const trackingData = ({ eventAction, eventCategory, eventLabel }) => {
  return {
    ...(eventAction && { 'data-event-action': eventAction }),
    ...(eventCategory && { 'data-event-category': eventCategory }),
    ...(eventLabel && { 'data-event-label': eventLabel }),
    onClick: trackEvent,
  }
}

const TrackedLink = ({
  children,
  eventAction,
  eventCategory,
  eventLabel,
  to,
  ...rest
}) => {
  const data = trackingData({ eventAction, eventCategory, eventLabel })
  const destination = linkDestination(to)
  const LinkComponent = linkComponent(to)

  return (
    <LinkComponent {...destination} {...data} {...rest}>
      {children}
    </LinkComponent>
  )
}

TrackedLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  eventAction: PropTypes.string,
  eventCategory: PropTypes.string,
  eventLabel: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default TrackedLink
