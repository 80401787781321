import { Helmet } from 'react-helmet'
import React from 'react'
import { withAssetPrefix } from 'gatsby-link'

const SiteHead = () => {
  const fonts = [
    'Rubik:wght@300;400;500;700',
    'Roboto:wght@300;400;500;700',
  ].join('&family=')
  const fontsUrl = `https://fonts.googleapis.com/css2?family=${fonts}&display=swap`

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <link crossOrigin="" href="https://fonts.gstatic.com" rel="preconnect" />
      <link as="style" href={fontsUrl} rel="preload" />
      <link
        href={fontsUrl}
        media="print"
        onLoad="this.media='all'"
        rel="stylesheet"
      />
      <noscript>{`<link href="${fontsUrl}" rel="stylesheet" />`}</noscript>
      <link rel="icon" href={withAssetPrefix('favicon.png')} />
      <script>
        {`(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${process.env.GATSBY_HOTJAR_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </script>
    </Helmet>
  )
}

export default SiteHead
